import Vue from "vue";
import FetchService from "./fetch-service.js";

const vue = new Vue();

class PoliticaPrivacidadeService extends FetchService {

    async consultarPolitica(validar = true) {
        const route = `${vue.$globals.endpoint}politicaPrivacidade`;
        return await this.fetchResponse(
            "GET", 
            vue.$globals.headerPadrao, 
            null, 
            false, 
            route,
            validar
        );
    }
    
    async cadastrar(obj) {
        const route = `${vue.$globals.endpoint}politicaPrivacidade`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            obj, 
            false, 
            route
        );
    }

    async atualizar(obj) {
        const route = `${vue.$globals.endpoint}politicaPrivacidade`;
        return await this.fetchResponse(
            "PUT", 
            vue.$globals.headerPadrao, 
            obj, 
            false, 
            route
        );
    }
}

export default PoliticaPrivacidadeService;